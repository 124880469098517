<template>
  <div class="box">
    <van-nav-bar
      :title="detailFrom.subjectName"
      left-text="返回"
      left-arrow
      @click-left="Back()"
    />
    <div class="header">
      <div class="detail-title">{{ detailFrom.subjectName }}</div>
      <div class="header-desc">
        发布时间:{{ detailFrom.publishTime }} 来源:{{ detailFrom.realName }}
      </div>
    </div>
    <div class="content">
      <img v-for="(item, index) in imgs" :key="index" :src="item" alt="" />
      <div class="text" v-html="detailFrom.subjectDesc"></div>
    </div>
    <div class="button-but">
      <div class="turnPage-btn" @click="setPage(true)">上一篇</div>
      <div class="turnPage-btn" @click="setPage(false)">下一篇</div>
    </div>
  </div>
</template>
<script>
import { getNewlistDetail } from "../../service/Newlist"
import { NavBar } from "vant"
export default {
  name: "Newdetail",
  data() {
    return {
      userId: 20201028001001,
      detailFrom: {},
      imgs: [],
      current: 0
    }
  },
  components: {
    VanNavBar: NavBar
  },
  props: {
    dataArr: Array
  },
  mounted() {
    this.getlistid()
  },
  methods: {
    getlistid() {
      const detailId = this.$route.params && this.$route.params.id
      getNewlistDetail(detailId, this.userId).then((res) => {
        this.detailFrom = res.data
        // 处理文本
        this.detailFrom.subjectDesc = JSON.parse(
          JSON.stringify(this.detailFrom.subjectDesc).replace(
            /\\n/g,
            '</br><text style=\\"text-indent: 2em;display: inline-block;\\">&nbsp;</text>'
          )
        )
        this.detailFrom.subjectDesc = "&nbsp;" + this.detailFrom.subjectDesc
        this.imgs = this.detailFrom.imageUrl.split(",")
      })
    },
    // setPage(type) {
    //   if (this.dataArr.length === 0) {
    //     Toast("没有可以翻页的数据")
    //     return false
    //   }
    //   var swiper = null
    //   if (type) {
    //     swiper = this.dataArr[this.current - 1]
    //   } else {
    //     swiper = this.dataArr[this.current + 1]
    //   }
    //   if (swiper) {
    //     this.current = type ? this.current - 1 : this.current + 1
    //     return false
    //   }
    //   // 查找当前页面所在的index 上一页减一 下一页加一
    //   var detailIndex = this.dataArr.findIndex((item) => {
    //     return this.detailFrom[this.current].detailId === item.id
    //   })
    //   detailId = null
    //   if (detailIndex != -1) {
    //     if (type) {
    //       swiper = this.dataArr[this.current - 1]
    //     } else {
    //       swiper = this.dataArr[this.current + 1]
    //     }
    //   }
    // },
    Back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  .header {
    padding: 1.6vh 1.9vw;
    text-align: center;
    border-bottom: 1px solid #bcbcbc;
    .detail-title {
      margin-bottom: 1.9vh;
      font-size: 3.1vh;
      font-weight: bolder;
      color: #131313;
      line-height: 1.5;
    }
  }
  .content {
    img {
      width: 100%;
      margin-top: 1vh;
      margin-bottom: 1.5vh;
    }
    .text {
      text-indent: 2em;
      font-size: 2.7vh;
      line-height: 1.8;
    }
  }
  .button-but {
    display: flex;
    justify-content: space-between;
    div {
      padding: 10px 15px;
      border: 1px solid #ddd;
      color: #000;
      font-size: 18px;
      text-align: center;
      border-radius: 5px;
    }
  }
}
</style>
