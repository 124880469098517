import server from "./axios.config.js";

// export const getNewList = function () {
//   var channelName = '驻村一线'
//   return server({
//     method: "get",
//     url: `http://110.87.103.59:18086/publisher/article/getTopImageListByChannel?channelName=${channelName}&top=2`
//   })
// }
export const getNewList = (userId, pageNum, pagesize) => {
  return server({
        method: "get",
        url: `http://8.131.69.31:9011/api/service/listPageMyStudyExchange?userId=${userId}&pageNum=${pageNum}&pagesize=${pagesize}`
      })
}

export const getNewlistDetail = function (detailId, userId) {
  return server({
    method: 'get',
    url: `http://8.131.69.31:9011/api/service/findExperienceShareDetail?detailId=${detailId}&userId=${userId}`
  })
}
